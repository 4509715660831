var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.is = void 0;
/**
 * Returns the object type of the given payload
 */

function isType(type) {
  return function (o) {
    return {}.toString.call(o).slice(8, -1) === type;
  };
}
/**
 * Collection of type checking functions
 */


var is = {
  array: Array.isArray,
  blob: isType("Blob"),
  boolean: isType("Boolean"),
  date: isType("Date"),
  defined: function defined(o) {
    return !(this.undefined(o) || this.null(o));
  },
  file: isType("File"),
  function: isType("Function"),
  map: isType("Map"),
  null: isType("Null"),
  number: isType("Number"),
  plainObject: function plainObject(o) {
    if (!isType("Object")(o)) return false;
    return o.constructor === Object && Object.getPrototypeOf(o) === Object.prototype;
  },
  object: isType("Object"),
  regExp: isType("RegExp"),
  string: isType("String"),
  symbol: isType("Symbol"),
  undefined: isType("Undefined"),
  weakMap: isType("WeakMap")
};
exports.is = is;
var _default = is;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;
const _is = exports.is;
export { _is as is };